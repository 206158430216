<template>
  <ValidationProvider v-slot="{ errors, valid }" :vid="vid" :name="$attrs.name || $attrs.label" :rules="rules" class="validated-select">
    <ControlField grouped :horizontal="horizontal" v-bind="$attrs" :type="{ 'is-danger': errors[0] }" :custom-class="verified ? 'is-verified' : ''">
      <o-input expanded v-if="readonly" :value="readOnlyText" :readonly="true" />
      <o-select :name="$attrs.name" expanded :value="innerValue" @input="onInput" :field="$attrs.name || $attrs.label" :disabled="disabled" v-if="!readonly">
        <option v-for="option in options" :key="reduce(option)" :value="reduce(option)">
          {{ getOptionLabel(option) }}
        </option>
      </o-select>
    </ControlField>
    <p class="help is-danger">{{ errors[0] }}</p>
  </ValidationProvider>
</template>

<script>
export default {
  props: {
    horizontal: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    vid: {
      type: String,
      default: ''
    },
    rules: {
      type: [Object, String],
      default: ''
    },
    // must be included in props
    value: {
      type: null,
      default: null
    },
    options: {
      type: Array,
      default: () => []
    },
    reduce: {
      type: Function,
      default: function(val) {
        return val;
      }
    },
    getOptionLabel: {
      type: Function,
      default: function(val) {
        return val[this.optionLabel];
      }
    },
    optionLabel: {
      type: String,
      default: 'name'
    },
    fallbackOptionLabel: {
      type: String,
      default: 'name'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    verified: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    innerValue: ''
  }),
  computed: {
    readOnlyText() {
      const arr = this.options || [];
      const item = arr.find(item => item.value === this.innerValue);
      if (item) {
        return this.getOptionLabel(item);
      }
      return '';
    }
  },
  watch: {
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
  methods: {
    onInput(val) {
      this.$emit('input', val);
    },
    $tt(text) {
      if (text.indexOf('.') === -1) {
        return text;
      }
      return this.$root.$t(text);
    }
  }
};
</script>

<style lang="scss" scoped>
.help {
  min-height: 17px;
  margin-bottom: 6px;
}
.field {
  margin-bottom: 0;
}
::v-deep .select,
::v-deep select {
  display: block;
  width: 100%;
}
</style>
